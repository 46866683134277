import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  dialogTitleTypography: {},
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
}));

/**
 * A dialog with a title and a close button.
 * @param {string} title - The title of the dialog.
 * @param {boolean} open - Whether the dialog is open.
 * @param {function} handleClose - Called when the dialog is closed.
 * @param {Object} [dialogOverrides] - Additional props for the dialog component
 * @param {Object} dialogTitleStyle - Override style of dialog title
 * @param {Object} dialogContentStyle - Override style of dialog content box
 * @param {JSX.Element} [children] - The content of the dialog.
 */
const StyledDialog = ({
  open,
  handleClose,
  title = "Dialog Title",
  dialogOverrides,
  children,
  dialogTitleStyle,
  dialogContentStyle,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      aria-labelledby={`dialog-${title}`}
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      {...dialogOverrides}
    >
      <DialogTitle
        disableTypography
        onClose={handleClose}
        className={classes.dialogTitle}
      >
        <Typography
          className={classes.dialogTitleTypography}
          variant={"h6"}
          style={dialogTitleStyle}
        >
          {title}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className={classes.dialogContent}
        style={dialogContentStyle}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default StyledDialog;
