import axios from "axios";
import apiErrorHandler from "./apiErrorHandler";

let SERVER_URL = "https://api.blackhyve.com/api/"
//let SERVER_URL = "https://api-staging.blackhyve.com/api/";
//const SERVER_URL = "https://c81851218cac.ngrok.io/api/";

let cancelToken = axios.CancelToken;
let source = cancelToken.source();

export const setServerUrl = (url) => {
  SERVER_URL = url;
};

let additionalHeaders = {};
export const setServerAdditionalHeaders = (headers) => {
  additionalHeaders = headers;
};

export const axiosRequestHandler = async (
  url,
  method,
  body,
  type = null,
  isFileUpload = false
) => {
  try {
    if (type === "CANCEL_API_REQUEST") {
      source.cancel("Operation canceled by the user.");
      let CancelToken = axios.CancelToken;
      source = CancelToken.source();
    }
    let headers = {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      ...additionalHeaders,
    };
    headers["Content-Type"] = isFileUpload
      ? "multipart/form-data;"
      : "application/json";

    const accessToken = localStorage.getItem("accessToken");
    if (accessToken && accessToken !== "undefined") {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }
    const result = await axios({
      url: `${SERVER_URL}${url}`,
      defaults: {
        timeout: 20000,
      },
      data: body,
      headers: headers,
      method: method,
      cancelToken: source.token,
    });

    return { error: null, response: result.data };
  } catch (error) {
    const apiError = apiErrorHandler(error);
    return apiError;
  }
};
