import * as React from "react";

const SvgBlackhyveIcon = (props) => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.36362 0L7.10908 0.864865V10.8108L5.36362 9.94595V4.47016L2.74545 5.95763V10.9073L8.2 14.0062L13.6545 10.9073V5.95763L11.0364 4.47016V9.94595L9.29092 10.8108V0.864865L11.0364 0V2.47633L15.4 4.95546V11.9094L8.2 16L1 11.9094V4.95546L5.36362 2.47633V0Z"
      fill={props.color ? props.color : "#FFF"}
    />
  </svg>
);

export default SvgBlackhyveIcon;
