import { LoginPage } from "@blackhyve/common";
import { login } from "../redux/slices/Auth";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const LoginPageComponent = () => {
  const dispatch = useDispatch();

  const { loggedIn, loading, error } = useSelector((state) => state.auth);

  const handleLogin = (loginDetails) => {
    dispatch(login(loginDetails));
  };

  if (loggedIn) {
    return <Redirect to="/workspaces" />;
  }

  return (
    <LoginPage
      error={error}
      loading={loading}
      loggedIn={loggedIn}
      handleLogin={handleLogin}
    />
  );
};

export default LoginPageComponent;
