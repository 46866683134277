import {
  useState,
  Fragment,
  memo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import clsx from "clsx";
import times from "lodash/times";
import isEqual from "lodash/isEqual";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import MoreOptionDialog from "./MoreOptionDialog";
import IconButton from "@material-ui/core/IconButton";
import InlineEditCardTitle from "./InlineEditCardTitle";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import makeStyles from "@material-ui/core/styles/makeStyles";
import NoDataAvailableComponent from "./NoDataAvailableComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
  },
  row: {
    padding: "8px",
  },
  button: {
    "&:hover": {
      backgroundColor: "#1EB980",
      boxShadow: "none",
    },
    fontSize: "10px",
    padding: "4px 6px",
    boxShadow: "none",
    borderRadius: "1px",
    height: "24px",
    marginTop: "4px",
    textTransform: "capitalize",
    backgroundColor: "#1EB980",
    color: "white",
    marginLeft: "5px",
  },
  greenButton: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#1eb980",
      boxShadow: "none",
    },
    border: "#1eb980",
    boxShadow: "none",
    borderRadius: "0px",
    textTransform: "capitalize",
    backgroundColor: "#1eb980",
    color: "white",
    height: "20px",
    fontSize: "14px",
    fontWeight: "700",
  },
  horizontalLine: {
    color: "#b7b6b6",
    paddingTop: "4px",
    paddingRight: "10px",
    fontWeight: "lighter",
  },
  labelButton: {
    margin: "auto",
    color: "rgba(0, 0, 0, 0.6)",
    cursor: "pointer",
  },
  phasesIconLabel: {
    margin: "auto",
    color: "rgba(0, 0, 0, 0.6)",
    cursor: "pointer",
  },
  justifyCenter: {
    margin: "auto",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  dialogPaper: {
    borderRadius: "5px",
  },
  gridBorder: {
    alignItems: "center",
    borderLeft: "1px solid #e7e4e4",
    borderRight: "1px solid #e7e4e4",
    borderBottom: "1px solid #e7e4e4",
    backgroundColor: "white",
  },
  headingText: {
    textTransform: "uppercase",
    textAlign: "center",
    margin: "auto",
    fontSize: "12px",
    color: "grey",
  },
  valueText: {
    color: "black",
    textAlign: "center",
    fontSize: "13px",
  },
  projectValueText: {
    fontSize: "9px",
    margin: "1px",
    border: "1px solid #B3B3B3",
    borderRadius: "2px",
  },
  gridHeading: {
    justifyContent: "center",
    borderBottom: "1px solid #e7e4e4",
    padding: "10px 8px 0 8px",
  },
  mainGridContainer: {
    padding: "30px",
    backgroundColor: "#FAFBFC",
  },
  contractorButton: {
    "&:hover": {
      background: "none",
      boxShadow: "none",
    },
    background: "none",
    fontSize: "10px",
    boxShadow: "none",
    color: "#8E8E90",
  },
  textField: {
    [`&.MuiInputBase-input`]: {
      font: "revert",
      borderRadius: "2px",
    },
    [`& focus`]: {
      outlined: "none",
    },
    [`& fieldset`]: {
      borderRadius: "2px",
    },
  },
  taskValues: {
    [`&.MuiOutlinedInput-adornedStart`]: {
      paddingLeft: "0px",
    },
    fontSize: "12px",
    height: "31px",
  },
  calenderStyle: {
    padding: "1px",
  },
  skeletonRow: {
    width: "98%",
    height: "41px !important",
    margin: "auto",
    borderRadius: "0px !important",
  },
}));

const RowComponent = memo(
  (props) => {
    const { row, rowItems, isMenu, optionMenu, handleDetails, index } = props;
    const classes = useStyles();
    const inLineEditRequestParameters = {
      id: row.id,
      isEmpty: row.isEmpty,
      index,
    };
    const menuActionRequestParameters = {
      ...row,
      index,
      isEmpty: row.isEmpty,
    };
    const [openMoreOptionDialog, setOpenMoreOptionDialog] = useState(null);

    let isBeingEdited = false;

    const handleBeingEdited = () => {
      isBeingEdited = !isBeingEdited;
    };

    const handleOpenMoreOptionDialog = useCallback((event) => {
      setOpenMoreOptionDialog(event.target);
    }, []);

    const handleCloseMoreOptionDialog = useCallback(() => {
      setOpenMoreOptionDialog(null);
    }, []);

    return (
      <Grid
        item
        container
        style={row.isBeingloaded || row.loading ? { padding: "3px" } : {}}
        className={clsx(classes.gridBorder, classes.row, "my-panel")}
        id={row.id}
        onClick={(event) =>
          !isBeingEdited ? handleDetails && handleDetails(event, row.id) : null
        }
      >
        {row.isBeingloaded || row.loading ? (
          <Skeleton variant="text" className={classes.skeletonRow} />
        ) : (
          rowItems.map(
            ({
              id,
              name,
              width,
              fieldName,
              icon,
              style,
              endIcon,
              handleUpdate,
              isEditable,
              isBlankAllowed,
              className,
              defaultValue,
              handleRowClick,
              iconFieldName,
              textToDisplayTransform,
              isSelect,
              selectOptions,
              checkCanEdit,
            }) => {
              const IconComponent = icon;
              return (
                <Grid
                  item
                  xs={width}
                  key={`${fieldName}-${row.id}`}
                  id={id && id}
                  style={style}
                  className={className}
                  onClick={(event) => {
                    handleRowClick && handleRowClick(event, row);
                  }}
                >
                  {iconFieldName ? (
                    <IconComponent
                      defaultInitials={row[iconFieldName]}
                      name={row[fieldName]}
                      id={row.id}
                    />
                  ) : (
                    icon && icon
                  )}
                  <InlineEditCardTitle
                    id={row[fieldName] == "start_condition" && row.id}
                    defaultValue={defaultValue}
                    handleUpdate={handleUpdate}
                    handleBeingEdited={handleBeingEdited}
                    textToDisplay={row[fieldName]}
                    textToDisplayTransform={textToDisplayTransform}
                    fieldName={fieldName}
                    rowData={row}
                    isEditable={isEditable}
                    isBlankAllowed={isBlankAllowed}
                    textBoxClass={"inlineEditText"}
                    requestParameters={inLineEditRequestParameters}
                    isDefaultEdit={row.isEmpty && fieldName == "name"}
                    isSelect={isSelect}
                    selectOptions={selectOptions}
                    checkCanEdit={checkCanEdit}
                  />
                  {endIcon && endIcon}
                </Grid>
              );
            }
          )
        )}

        {isMenu &&
        (row.isBeingloaded !== undefined || row.loading !== undefined
          ? row.isBeingloaded === false || row.loading === false
          : true) ? (
          <Grid
            container
            item
            xs={1}
            className={classes.valueText}
            justifyContent="flex-end"
          >
            <IconButton
              id="more-option-popover"
              aria-label="Filter"
              size="small"
              className="iconImage"
              disableRipple={true}
              disableFocusRipple={true}
              style={{ padding: 0 }}
              onClick={handleOpenMoreOptionDialog}
            >
              <MoreHorizIcon size="small" className={"moreHoriz"} />
            </IconButton>
            {openMoreOptionDialog && (
              <MoreOptionDialog
                optionMenu={optionMenu}
                id="more-option-popover"
                open={openMoreOptionDialog}
                anchorEl={openMoreOptionDialog}
                requestParameter={menuActionRequestParameters}
                handleClose={handleCloseMoreOptionDialog}
              />
            )}
          </Grid>
        ) : null}
      </Grid>
    );
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps.row, nextProps.row);
  }
);

const ListComponent = memo((props) => {
  const classes = useStyles();
  const {
    list,
    rowItems,
    isMenu,
    optionMenu,
    isProject,
    handleDetails,
    style,
    isLoading,
    skeletonNoOfRows,
  } = props;

  const loader = useRef(null);
  const [isNewPageLoading, setIsNewPageLoading] = useState(false);

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      //setIsNewPageLoading(true)
      console.log("Loading...");
      //   setPage && setPage(prev + 1);
    }
  }, []);

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0.5,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  return isLoading ? (
    <SkeletonListComponent
      noOfRows={skeletonNoOfRows ? skeletonNoOfRows : 15}
      gridContainerStyle={style}
    />
  ) : list.length < 1 ? (
    <Grid item xs={12}>
      <NoDataAvailableComponent />
    </Grid>
  ) : (
    <Grid
      container
      style={style && style}
      className={classes.mainGridContainer}
    >
      <Grid item container className={classes.gridHeading}>
        {rowItems &&
          rowItems.map(({ title, width }) => {
            return (
              <HeaderTitleComponent key={title} title={title} width={width} />
            );
          })}
        <Grid item xs={1} className={classes.headingText}></Grid>
      </Grid>
      <ListMapComponent
        list={list}
        isMenu={isMenu}
        rowItems={rowItems}
        isProject={isProject}
        optionMenu={optionMenu}
        handleDetails={handleDetails}
      />
      {isNewPageLoading && <div>Loading...</div>}
      <div className="loading-ref-class" ref={loader} />
    </Grid>
  );
});

const HeaderTitleComponent = memo(
  ({ width, title }) => {
    const classes = useStyles();
    return (
      <Grid item xs={width} key={title} className={classes.headingText}>
        {title && title}{" "}
      </Grid>
    );
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
  }
);

const ListMapComponent = memo(
  ({ list, isMenu, rowItems, isProject, optionMenu, handleDetails }) => {
    return (
      list &&
      list.map((element, index) => {
        return (
          <RowComponent
            index={index}
            row={element}
            isMenu={isMenu}
            key={element.id}
            rowItems={rowItems}
            isProject={isProject}
            optionMenu={optionMenu}
            handleDetails={handleDetails}
          />
        );
      })
    );
  }
);

const SkeletonListComponent = memo(({ noOfRows, gridContainerStyle }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      style={gridContainerStyle}
      className={classes.mainGridContainer}
      direction="column"
    >
      {times(noOfRows, (row) => {
        return (
          <Grid
            item
            key={`${row}-skeleton`}
            container
            className={clsx(classes.gridBorder, classes.row, "my-panel")}
            style={{
              padding: 0,
              borderTop: row == 0 ? "1px solid #e7e4e4" : "",
            }}
          >
            <Skeleton variant="text" className={classes.skeletonRow} />
          </Grid>
        );
      })}
    </Grid>
  );
});

export default ListComponent;
