import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import reducer from "./slices";
import thunk from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";

const middleware = [thunk];

export default configureStore({
  reducer,
  middleware,
});
