import FormComponent from "./formComponent";
import LoginLayoutComponent from "../../components/LoginLayoutComponent";

/**
 * Login Page Component
 * @param {Object} handleLogin
 * @param {Boolean} loggedIn
 * @param {Object} error
 * @param {Boolean} requesting
 * @returns {JSX.Element} of form component
 */

const LoginPageComponent = ({ handleLogin, loggedIn, error, requesting }) => {
  return (
    <LoginLayoutComponent>
      <FormComponent
        handleLogin={handleLogin}
        loggedIn={loggedIn}
        error={error}
        requesting={requesting}
      />
    </LoginLayoutComponent>
  );
};

export default LoginPageComponent;
