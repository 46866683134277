import { axiosRequestHandler } from "../helpers/apiHelper";

export const FetchWorkspacesAPI = async (data) => {
  try {
    return await axiosRequestHandler("admin/workspaces", "get");
  } catch (error) {
    throw new Error({ error: true, message: "Something went wrong..!!" });
  }
};

export const SaveWorkspaceAPI = async (data) => {
  try {
    return await axiosRequestHandler("admin/workspaces", "post", data);
  } catch (error) {
    throw new Error({ error: true, message: "Something went wrong..!!" });
  }
};

export const UpdateWorkspaceAPI = async (data) => {
  try {
    return await axiosRequestHandler(
      `admin/workspaces/${data.id}`,
      "patch",
      data
    );
  } catch (error) {
    throw new Error({ error: true, message: "Something went wrong..!!" });
  }
};

export const DeleteWorkspaceAPI = async (data) => {
  try {
    return await axiosRequestHandler(
      `admin/workspaces/${data.id}`,
      "delete",
      data
    );
  } catch (error) {
    throw new Error({ error: true, message: "Something went wrong..!!" });
  }
};
