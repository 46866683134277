import { useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import logo from "../assets/images/blackhyve-logo.png";
import makeStyles from "@material-ui/core/styles/makeStyles";
import coverImage from "../assets/images/login-background.jpg";
import compressedBackgroundImage from "../assets/images/login-background-compress.jpg";
import "../assets/styles/login.css";

/**
 * Login layout component
 * @param {JSX} children
 * @returns {JSX.Element}
 */
const LoginLayoutComponent = ({ children }) => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        container
        className="login-layout-container"
        direction="column"
      >
        <LogoAndNameComponent />
        <Grid container className="login-form">
          {children}
        </Grid>
      </Grid>
      <MainPageImageComponent />
    </Grid>
  );
};

/**
 * Logo Name component
 * @returns {JSX.Element}
 */
const LogoAndNameComponent = () => {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="column"
      style={{ flexGrow: 1 }}
    >
      <Grid container justify="center">
        <img src={logo} alt="" style={{ height: "170px" }} />
        <br />
        <br />
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: compressedBackgroundImage,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

const MainPageImageComponent = () => {
  const classes = useStyles();
  const containerRef = useRef();

  useEffect(() => {
    if (containerRef && containerRef.current) {
      const img = new Image();
      img.src = containerRef.current.dataset.src;
      img.onload = () => {
        containerRef.current.style.backgroundImage = `url(${containerRef.current.dataset.src})`;
      };
    }
  }, [containerRef]);

  return (
    <Grid
      item
      xs={false}
      sm={6}
      md={8}
      container
      ref={containerRef}
      data-src={coverImage}
      className={classes.image}
    ></Grid>
  );
};

export default LoginLayoutComponent;
