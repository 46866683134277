import store from "./redux/store";
import { Provider } from "react-redux";
import LoginPage from "./pages/LoginPage";
import { SnackbarProvider } from "notistack";
import { AppTheme } from "@blackhyve/common";
import WorkspacePage from "./pages/WorkspacePage";
import PrivateRoute from "./components/PrivateRoute";
import { MuiThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";

const App = () => {
  return (
    <Provider store={store}>
      <CssBaseline>
        <MuiThemeProvider theme={AppTheme}>
          <SnackbarProvider maxSnack={3}>
            <Router>
              <Switch>
                <PrivateRoute path="/workspaces" component={WorkspacePage} />
                <Route path="/">
                  <LoginPage />
                </Route>
                <App />
              </Switch>
            </Router>
          </SnackbarProvider>
        </MuiThemeProvider>
      </CssBaseline>
    </Provider>
  );
};

export default App;
