import { useDispatch } from "react-redux";
import { logout } from "../redux/slices/Auth";
import { useHistory } from "react-router-dom";
import { MainLayoutComponent } from "@blackhyve/common";

/**
 * UserLayoutComponent having header panel
 * @param {JSX} children
 * @returns JSX Component
 */

const UserLayout = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    dispatch(logout());
    setTimeout(() => {
      history.push("/");
    }, 1000);
  };

  const headerMenus = [
    {
      name: "Workspaces",
      path: "/workspaces",
    },
  ];

  const settingMenuOptions = [{ name: "Logout", action: handleLogout }];

  return (
    <MainLayoutComponent
      headerMenus={headerMenus}
      settingMenuOptions={settingMenuOptions}
    >
      {children}
    </MainLayoutComponent>
  );
};

export default UserLayout;
