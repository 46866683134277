import { createTheme } from "@material-ui/core";

export const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#1eb980",
      contrastText: "#fff",
    },
  },
  typography: {
    fontSize: 13,
    h6: {
      textTransform: "uppercase",
      color: "#C4C4C4",
    },
  },
  overrides: {
    PrivateNotchedOutline: {
      root: {
        borderWidth: "2px",
        borderRadius: "4px",
        verticalAlign: "middle",
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderRadius: "2px",
        border: "1px solid lightGrey",
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        "&$checked": {
          color: "darkGrey",
        },
      },
    },
    MuiInputBase: {
      root: {
        "&:focus": {
          outlined: "none",
        },
        "&:fieldset": {
          borderRadius: "2px",
          border: "1px solid lightGrey",
        },
      },
      input: {
        font: "revert",
        borderRadius: "2px",
      },
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: "0px",
      },
    },
    MuiButton: {
      root: {
        borderRadius: "2px",
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
          padding: "10px",
        },
      },
    },
  },
});
