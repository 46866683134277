import { useEffect, useState, useCallback, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import UserLayout from "../components/UserLayout";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchWorkspaces,
  saveWorkspace,
  addBlankWorkSpace,
  deleteWorkspace,
  selectWorkspaceEntities,
  clearWorkspaceActions,
} from "../redux/slices/Workspace";
import {
  ListComponent,
  PagePanelHeader,
  StyledDialog,
} from "@blackhyve/common";
import CircularProgress from "@material-ui/core/CircularProgress";

const WorkspacePage = () => {
  const dispatch = useDispatch();
  const [workspaceList, setWorkspaceList] = useState([]);
  const { loading, error, isActionProcessed, isActionProcessing } = useSelector(
    (state) => state.workspace
  );
  const workspaces = useSelector(selectWorkspaceEntities);
  const [deleteWorkspaceDialog, setDeleteWorkspaceDialog] = useState({
    open: false,
  });

  const handleCreateWorkspace = () => {
    dispatch(addBlankWorkSpace());
  };

  const handleSaveWorkspace = (requestParameters) => {
    const databaseName = requestParameters.name
      .replace(/\s/, "_")
      .trim()
      .toLowerCase();

    dispatch(
      saveWorkspace({
        ...requestParameters,
        id: requestParameters.id,
        isEmpty: requestParameters.isEmpty,
        company: requestParameters.name,
        database: `${databaseName}_db`,
      })
    );
  };

  const handleShowDeleteWorkspaceConfirmation = (requestParameters) => {
    console.log(requestParameters);
    setDeleteWorkspaceDialog({
      ...requestParameters,
      open: true,
      id: requestParameters.id,
      name: requestParameters.name,
    });
  };

  const handleCloseDeleteWorkspaceConfirmation = () => {
    setDeleteWorkspaceDialog({ open: false });
    dispatch(clearWorkspaceActions());
  };

  const handleDeleteWorkspace = (requestParameters) => {
    dispatch(deleteWorkspace(requestParameters));
  };

  const handleLoginWorkspace = (requestParameters) => {
    console.log("requestParameters =>", requestParameters);
    const url = `https://staging.blackhyve.com/workspace/${requestParameters.id}/projects`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const IconComponentWrapper = useCallback(() => {
    return (
      <IconButton size="small">
        <img
          src="/assets/images/workspace-icon.svg"
          alt="workspace"
          style={{ marginRight: 5 }}
        />
      </IconButton>
    );
  }, []);

  const rowItems = [
    {
      title: "",
      fieldName: "name",
      width: 3,
      isEditable: true,
      icon: IconComponentWrapper,
      iconFieldName: "initials",
      handleUpdate: handleSaveWorkspace,
      style: { display: "flex", alignItems: "center" },
    },
    {
      title: "Database Name",
      fieldName: "database",
      width: 3,
      isEditable: false,
      handleUpdate: handleSaveWorkspace,
      className: "justify-content-center",
    },
    {
      title: "Created At",
      isEditable: false,
      isDateField: true,
      width: 3,
      fieldName: "createdAt",
      className: "justify-content-center",
    },
    {
      title: "Last Updated at",
      width: 2,
      isEditable: false,
      isDateField: true,
      fieldName: "updatedAt",
      className: "justify-content-center",
    },
  ];

  const headingItems = [
    {
      title: "+ Workspace",
      handleClick: handleCreateWorkspace,
      width: 4,
    },
  ];

  const optionMenu = [
    {
      name: "Login Workspace",
      imagePath: "/assets/images/login-icon.png",
      action: handleLoginWorkspace,
    },
    {
      name: "Delete Workspace",
      imagePath: "/assets/images/delete-icon-red.svg",
      action: handleShowDeleteWorkspaceConfirmation,
      style: { color: "red" },
    },
  ];

  useEffect(() => {
    dispatch(fetchWorkspaces());
  }, []);

  useEffect(() => {
    setWorkspaceList(workspaces);
  }, [workspaces]);

  useEffect(() => {
    if (isActionProcessed) {
      handleCloseDeleteWorkspaceConfirmation();
    }
  }, [isActionProcessed]);

  return (
    <UserLayout>
      <Grid container style={{ display: "flex" }}>
        <Grid item xs={12}>
          <Grid item>
            <PagePanelHeader
              isSearchEnabled={true}
              buttonItems={headingItems}
            />
          </Grid>
        </Grid>
        <ListComponent
          isMenu={true}
          rowItems={rowItems}
          list={Object.values(workspaceList)}
          optionMenu={optionMenu}
          isLoading={loading}
        />
        {deleteWorkspaceDialog.open && (
          <DeleteWorkspaceConfirmationDialog
            error={error}
            open={deleteWorkspaceDialog.open}
            id={deleteWorkspaceDialog.id}
            name={deleteWorkspaceDialog.name}
            loading={isActionProcessing}
            handleDeleteWorkspace={handleDeleteWorkspace}
            handleClose={handleCloseDeleteWorkspaceConfirmation}
          />
        )}
      </Grid>
    </UserLayout>
  );
};

/**
 *  A confirmation dialog to delete workspace.
 * @param {boolean} open - Open Dialog.
 * @param {string} id - Workspace id.
 * @param {string} name - Workspace name.
 * @param {boolean} loading - Action is loading.
 * @param {string} error - Error Message to display.
 * @param {object} handleClose - Close Confirmation popup
 * @param {object} handleDeleteWorkspace - Delete workspace API method
 * @returns
 */

const DeleteWorkspaceConfirmationDialog = ({
  open,
  id,
  name,
  error,
  loading,
  handleClose,
  handleDeleteWorkspace,
}) => {
  const [formState, setFormState] = useState({ confirmWorkspaceName: "" });
  const [errorState, setErrorState] = useState(false);

  const handleOnChange = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const handleKeyPress = (event) => {
    if (event.key != "Enter") return;
    handleSubmit();
  };

  const handleSubmit = () => {
    if (name.toLowerCase() !== formState.confirmWorkspaceName.toLowerCase()) {
      setErrorState(true);
      return;
    }
    handleDeleteWorkspace({
      id,
      confirm_workspace_name: formState.confirmWorkspaceName,
    });
  };

  const dialogContentStyle = {
    paddingTop: 0,
  };

  const dialogTitleStyle = {
    fontWeight: 600,
    color: "black",
    textTransform: "initial",
  };

  useEffect(() => {
    setErrorState(error);
  }, [error]);

  return (
    <StyledDialog
      title="Are you absolutely sure?"
      open={open}
      handleClose={handleClose}
      dialogTitleStyle={dialogTitleStyle}
      dialogContentStyle={dialogContentStyle}
    >
      {loading && <CircularProgress className="loading" />}
      <Grid
        container
        direction="column"
        spacing={2}
        style={loading ? { opacity: 0.3 } : {}}
      >
        <Grid item>
          <Typography>
            This action cannot be undone. This will permanently delete the
            <b> {name} </b> workspace, projects and remove all members
            associated.
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            Please type <b> {name} </b>to confirm.
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            autoFocus
            fullWidth
            size="small"
            type="text"
            variant="outlined"
            placeholder={name}
            onChange={handleOnChange}
            name="confirmWorkspaceName"
            onKeyDown={handleKeyPress}
            value={formState.confirmWorkspaceName}
            error={errorState}
            helperText={
              errorState ? "Workspace name is incorrect, please check" : ""
            }
          />
        </Grid>
        <Grid item>
          <Button className="button" fullWidth onClick={handleSubmit}>
            I Understand the consequences, delete the workspace
          </Button>
        </Grid>
      </Grid>
    </StyledDialog>
  );
};

export default WorkspacePage;
