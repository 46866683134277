import clsx from "clsx";
import moment from "moment";
import { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiSelect-root": {
      padding: "0px",
    },
    "& .MuiInputBase-root": {
      fontSize: "14px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "2px",
    },
    "& .MuiFormHelperText-root": {
      marginTop: "2px",
      marginLeft: "5px",
      fontSize: "11px",
    },
    //marginTop: '7px',
    verticalAlign: "middle",
    flexDirection: "row",
    alignItems: "center",
    //marginBottom: '7px',
  },
  labelName: {
    fontSize: 13,
    cursor: "pointer",
    display: "inline-flex",
    margin: "auto",
  },
  helperText: {
    fontSize: 12,
  },
}));

const InlineEditCardTitle = (props) => {
  const classes = useStyles();
  const {
    id,
    defaultValue,
    fieldName,
    isEditable,
    isBlankAllowed,
    handleUpdate,
    handleBeingEdited,
    isDefaultEdit,
    textBoxClass,
    textToDisplay,
    requestParameters,
    locationLabelStyle,
    editableTextBoxStyle,
    textToDisplayTransform,
    isSelect = false,
    selectOptions,
    rowData,
    checkCanEdit,
  } = props;

  let canEdit = true;

  if (isEditable && checkCanEdit) {
    const params = {};
    checkCanEdit.functionParams.forEach(
      (param) => (params[param] = rowData[param])
    );
    canEdit = checkCanEdit.functionObject(params);
  }

  const [isEditing, setIsEditing] = useState(isDefaultEdit);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (isDefaultEdit) {
      setIsEditing(isDefaultEdit);
    }
  }, [isDefaultEdit]);

  const handleIsEditing = () => {
    setIsEditing(!isEditing);

    // Check if is defined. Needs to be defined in parent component to if parent has onClick to prevent highlighting triggering
    if (handleBeingEdited) {
      handleBeingEdited();
    }
  };

  const handleUpdateName = (event) => {
    if (
      event.target &&
      ((event.target.value && event.target.value.length) || isBlankAllowed)
    ) {
      handleUpdate({ ...requestParameters, [fieldName]: event.target.value });
      setIsEditing(false);
      setError(false);
    } else {
      setError(true);
    }
  };

  return isEditing && isEditable && canEdit ? (
    <TextField
      autoFocus
      size="small"
      variant="outlined"
      defaultValue={textToDisplay}
      placeholder="Type a name..."
      select={isSelect}
      className={clsx(classes.textField, textBoxClass)}
      onBlur={handleUpdateName}
      onChange={isSelect ? handleUpdateName : null}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          handleUpdateName(event);
        }
      }}
      SelectProps={{
        open: true,
        onClose: () => setIsEditing(false),
        MenuProps: {
          transformOrigin: {
            horizontal: "",
          },
        },
      }}
      inputProps={{
        style: editableTextBoxStyle
          ? editableTextBoxStyle
          : {
              padding: "7px 6px 6px 10px",
              fontSize: 13,
            },
      }}
      error={error}
      helperText={error && "Cannot be empty"}
    >
      {isSelect && selectOptions !== undefined
        ? selectOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))
        : null}
    </TextField>
  ) : (
    <Typography
      variant="body1"
      className={textBoxClass}
      style={
        locationLabelStyle
          ? locationLabelStyle
          : {
              fontSize: 13,
              cursor: "pointer",
              display: "inline-flex",
              // margin: "auto"
            }
      }
      onClick={(event) => {
        id ? handleUpdate(event, id) : handleIsEditing();
      }}
    >
      {textToDisplay
        ? textToDisplay.includes("000000Z")
          ? textToDisplayTransform !== undefined
            ? textToDisplayTransform(moment(textToDisplay).format("MM/DD/YYYY"))
            : moment(textToDisplay).format("MM/DD/YYYY")
          : textToDisplayTransform !== undefined
          ? textToDisplayTransform(textToDisplay)
          : textToDisplay
        : defaultValue != undefined
        ? defaultValue
        : textToDisplayTransform !== undefined
        ? textToDisplayTransform(textToDisplay)
        : "-"}
    </Typography>
  );
};

export default InlineEditCardTitle;
