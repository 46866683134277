import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  SendResetPasswordLink,
  SetNewPassword,
  SignIn as signIn,
  SignOut as signOut,
} from "../../services/LoginService";

/**
 * Login async thunk
 * @param {object} signInData
 * @param {string} signInData.email - email address
 * @param {string} signInData.password - password
 */
export const login = createAsyncThunk(
  "auth/login",
  async (signInData, { rejectWithValue }) => {
    const { email, password } = signInData;
    const apiResponse = await signIn({ email, password });
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.error.message);
    } else if (apiResponse.response.error) {
      return rejectWithValue(apiResponse.response.error);
    } else {
      return apiResponse.response;
    }
  }
);

/**
 * Logout async thunk
 */
export const logout = createAsyncThunk(
  "auth/logout",
  async (undefined, { rejectWithValue }) => {
    const apiResponse = await signOut();
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.message);
    } else {
      return apiResponse.response;
    }
  }
);

/**
 * Reset password async thunk
 * @param {object} resetPasswordData
 * @param {string} resetPasswordData.email - email of the user to reset password for
 * @param {string} resetPasswordData.password - new password
 * @property {string} resetPasswordData.passwordConfirmation - new password confirmation
 */
export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (resetPasswordData, { rejectWithValue }) => {
    const apiResponse = await SetNewPassword(resetPasswordData);
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.error);
    } else if (apiResponse.response.error) {
      return rejectWithValue(apiResponse.response.error);
    } else {
      return apiResponse.response;
    }
  }
);

/**
 * Send password reset email async thunk
 * @param {string} email
 */
export const sendResetPasswordLink = createAsyncThunk(
  "auth/sendResetPasswordLink",
  async (email, { rejectWithValue }) => {
    const apiResponse = await SendResetPasswordLink({ email: email });
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.error);
    } else {
      return apiResponse.response;
    }
  }
);

const initialState = {
  loggedIn: undefined,
  loading: false,
  passwordReset: undefined,
  resetEmailSent: undefined,
  error: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuthErrors: (state) => {
      state.error = null;
    },
    isAuthenticated: (state) => {
      const isLoggedIn = localStorage.getItem("isLoggedIn");
      const accessToken = localStorage.getItem("accessToken");
      if (isLoggedIn && accessToken) {
        state.loggedIn = true;
      } else {
        state.loggedIn = false;
      }
    },
  },
  extraReducers: (builder) => {
    // Login reducers
    builder.addCase(login.fulfilled, (state, action) => {
      const { user, access_token } = action.payload;
      // const id = user['id'];
      localStorage.setItem("accessToken", access_token);
      localStorage.setItem("isLoggedIn", true);
      // state.data = user;
      state.loading = false;
      state.error = null;
      state.loggedIn = true;
      // state.id = id;
    });
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.data = null;
      state.error = action.payload;
      state.isLoggedIn = false;
      state.id = null;
    });

    // Logout reducers
    builder.addCase(logout.fulfilled, () => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("isLoggedIn");
      return initialState;
    });
    builder.addCase(logout.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Reset password reducers
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.passwordReset = true;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.passwordReset = undefined;
      state.loading = true;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.passwordReset = false;
      state.loading = false;
      state.error = action.payload;
    });

    // Send reset password link reducers
    builder.addCase(sendResetPasswordLink.fulfilled, (state) => {
      state.resetEmailSent = true;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(sendResetPasswordLink.pending, (state) => {
      state.resetEmailSent = undefined;
      state.loading = true;
    });
    builder.addCase(sendResetPasswordLink.rejected, (state, action) => {
      state.resetEmailSent = false;
      state.loading = false;
      state.error = action.payload;
    });
  },
});

const authReducer = authSlice.reducer;
export default authReducer;

export const { clearAuthErrors, isAuthenticated } = authSlice.actions;
