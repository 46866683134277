import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import {
  DeleteWorkspaceAPI,
  FetchWorkspacesAPI,
  SaveWorkspaceAPI,
  UpdateWorkspaceAPI,
} from "../../services/WorkspaceService";

/**
 * Fetch all workspaces
 */
export const fetchWorkspaces = createAsyncThunk(
  "workspace/fetchWorkspaces",
  async (undefined, { rejectWithValue }) => {
    console.log("CALLING");
    const apiResponse = await FetchWorkspacesAPI();
    if (apiResponse.error) {
      return rejectWithValue(apiResponse.error.message);
    } else if (apiResponse.response.error) {
      return rejectWithValue(apiResponse.response.error);
    } else {
      return apiResponse.response.data;
    }
  }
);

/**
 * @param {object} requestParameters
 * @param {string} requestParameters.company
 * @param {string} requestParameters.databse
 */
export const saveWorkspace = createAsyncThunk(
  "workspace/saveWorkspace",
  async (requestParameters, { rejectWithValue }) => {
    console.log("requestParameters =>", requestParameters);
    const apiResponse = requestParameters.isEmpty
      ? await SaveWorkspaceAPI(requestParameters)
      : await UpdateWorkspaceAPI({
          company: requestParameters.company,
          id: requestParameters.id,
        });

    if (apiResponse.error) {
      return rejectWithValue(apiResponse.error.message);
    } else if (apiResponse.response.error) {
      return rejectWithValue(apiResponse.response.error);
    } else if (apiResponse.response.errors) {
      return rejectWithValue(apiResponse.response.errors);
    } else {
      return apiResponse.response.data;
    }
  }
);

/**
 * Add Blank Workspace
 */
export const addBlankWorkSpace = createAsyncThunk(
  "workspace/addBlankWorkSpace",
  () => {
    return { id: Math.random(), name: "Workspace 1", isEmpty: true };
  }
);

/**
 * @param {object} requestParameters
 * @param {string} requestParameters.id
 * @param {string} requestParameters.confirm_workspace_name
 */
export const deleteWorkspace = createAsyncThunk(
  "workspace/deleteWorkspace",
  async (requestParameters, { rejectWithValue }) => {
    const apiResponse = await DeleteWorkspaceAPI(requestParameters);
    await new Promise((resolve, reject) => {
      setTimeout(() => resolve(), 5000);
    });
    if (apiResponse.error) {
      return rejectWithValue({
        ...apiResponse,
        ...requestParameters,
      });
    } else if (apiResponse.response.error) {
      return rejectWithValue({
        ...apiResponse.response.error,
        ...requestParameters,
      });
    } else if (apiResponse.response.errors) {
      return rejectWithValue({
        ...apiResponse.response.errors,
        ...requestParameters,
      });
    } else {
      return { ...apiResponse.response.data, ...requestParameters };
    }
  }
);

export const workspaceAdapter = createEntityAdapter();

const initialState = workspaceAdapter.getInitialState({
  error: null,
  loading: false,
  isActionProcessed: null,
  isActionProcessing: null,
});

export const workspaceSlice = createSlice({
  name: "workspaces",
  initialState,
  reducers: {
    clearWorkspaceActions: (state) => {
      state.isActionProcessed = null;
      state.isActionProcessing = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Workspace reducers
    builder.addCase(fetchWorkspaces.fulfilled, (state, action) => {
      const workspaces = action.payload.map(
        ({ company, database, created_at, updated_at, id }) => {
          return {
            id,
            name: company,
            database: database,
            createdAt: created_at,
            updatedAt: updated_at,
          };
        }
      );
      state.loading = false;
      workspaceAdapter.upsertMany(state, workspaces);
    });
    builder.addCase(fetchWorkspaces.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchWorkspaces.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    });

    // ADD WORKSPACE
    builder.addCase(addBlankWorkSpace.fulfilled, (state, action) => {
      workspaceAdapter.addOne(state, action.payload);
    });

    // SAVE WORKSPACE
    builder.addCase(saveWorkspace.fulfilled, (state, action) => {
      const { company, database, created_at, updated_at, id } = action.payload;
      const workspace = {
        id,
        createdAt: created_at,
        updatedAt: updated_at,
        name: company,
        database,
      };
      state.error = null;
      if (action?.meta?.arg?.id) {
        workspaceAdapter.removeOne(state, action?.meta?.arg?.id);
      }
      workspaceAdapter.upsertOne(state, workspace);
    });
    builder.addCase(saveWorkspace.pending, (state, action) => {
      workspaceAdapter.upsertOne(state, {
        ...action.meta.arg,
        isBeingloaded: true,
      });
    });
    builder.addCase(saveWorkspace.rejected, (state, action) => {
      state.data = [];
      state.error = action.payload;
      workspaceAdapter.updateOne(state, {
        ...action.meta.arg,
        isBeingloaded: false,
      });
    });

    // DELETE WORKSPACE

    builder.addCase(deleteWorkspace.fulfilled, (state, action) => {
      state.isActionProcessed = true;
      state.isActionProcessing = false;
      workspaceAdapter.removeOne(state, action.payload.id);
    });

    builder.addCase(deleteWorkspace.pending, (state, action) => {
      state.isActionProcessed = false;
      state.isActionProcessing = true;
    });

    builder.addCase(deleteWorkspace.rejected, (state, action) => {
      state.error = action.payload.message;
      state.isActionProcessing = false;
    });
  },
});

export default workspaceSlice.reducer;

export const { clearWorkSpaces, clearWorkspaceActions } =
  workspaceSlice.actions;

/** Selectors **/

export const {
  selectById: selectWrokspaceById,
  selectIds: selectWorkspaceIds,
  selectEntities: selectWorkspaceEntities,
  selectAll: selectAllWorkspaces,
  selectTotal: selectTotalWorkspaces,
} = workspaceAdapter.getSelectors((state) => state.workspace);
