import { combineReducers } from "redux";
import authReducer from "./Auth";
import workspaceReducer from "./Workspace";

const rootReducer = combineReducers({
  auth: authReducer,
  workspace: workspaceReducer,
});

export default rootReducer;
