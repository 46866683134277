import { axiosRequestHandler } from "../helpers/apiHelper";

export const SignIn = async (data) => {
  try {
    let response = await axiosRequestHandler("login", "post", data);
    return response;
  } catch (error) {
    throw new Error({ error: true, message: "Something went wrong..!!" });
  }
};

export const SignOut = async (data) => {
  try {
    const response = await axiosRequestHandler("logout", "post");
    return response;
  } catch (error) {
    throw new Error({ error: true, message: "Something went wrong..!!" });
  }
};

export const SetNewPassword = async (data) => {
  try {
    let response = await axiosRequestHandler("password/reset", "post", data);
    return response;
  } catch (error) {
    throw new Error({ error: true, message: "Something went wrong..!!" });
  }
};

export const SendResetPasswordLink = async (data) => {
  try {
    let response = await axiosRequestHandler("password/forgot", "post", data);
    return response;
  } catch (error) {
    throw new Error({ error: true, message: "Something went wrong..!!" });
  }
};
