import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";
import LinearProgress from "../../components/ProgressBarComponent";
import InputAdornment from "@material-ui/core/InputAdornment";

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: 11,
    borderRadius: 0,
    textTransform: "unset",
    backgroundColor: "#966E2A !important",
    color: "black",
    fontWeight: "400",
    border: "2px solid #966E2A",
    "&:hover": {
      backgroundColor: "#966E2A !important",
    },
    boxShadow: "none",
    padding: "4px 16px",
  },
}));

/**
 * Login Form Component
 * @param {Object} handleLogin
 * @param {Boolean} loggedIn
 * @param {Object} error
 * @param {Boolean} requesting
 * @returns {JSX.Element} of form component
 */

const LoginFormComponent = ({ handleLogin, loggedIn, error, requesting }) => {
  const classes = useStyles();
  const [progressBarStatus, setCompleted] = useState(0);
  const [formState, setFormState] = useState({
    email: "",
    password: "",
  });

  const handleOnChange = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin({ email: formState.email, password: formState.password });
  };

  return (
    <Grid container direction="column">
      <Grid item>
        {requesting && (
          <LinearProgress
            setCompleted={setCompleted}
            progressBarStatus={progressBarStatus}
          />
        )}
        <div className="alert-div">
          {error && loggedIn === false && (
            <div>
              <Alert className="custom-alert" severity="error" variant="filled">
                {error}
              </Alert>
            </div>
          )}
          <br />
          <br />
        </div>
      </Grid>
      <Grid item>
        <Container>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              name="email"
              size="small"
              required={true}
              variant="outlined"
              value={formState.email}
              onChange={handleOnChange}
              placeholder="Email Address"
              className="textBox-style"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className="input-icon-style">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
            <br />
            <br />
            <TextField
              fullWidth
              size="small"
              type="password"
              name="password"
              required={true}
              variant="outlined"
              placeholder="Password"
              onChange={handleOnChange}
              value={formState.password}
              inputProps={{ minLength: 4 }}
              className="textBox-style"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className="input-icon-style">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />
            <br />
            <Link href="/reset-password" className="link-txt">
              Forgot Password?
            </Link>
            <br />
            <br />
            <Button
              variant="contained"
              fullWidth={true}
              color="primary"
              size="medium"
              type="submit"
              className={classes.button}
            >
              Login
            </Button>
            <br />
            <div className="signup-link">
              <span className="text-msg-label">Don't have an account? </span>
              <Link href="/" className="link-txt" variant="body2">
                Sign Up
              </Link>
            </div>
          </form>
        </Container>
      </Grid>
    </Grid>
  );
};

export default LoginFormComponent;
