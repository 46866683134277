import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";

const headerComoponentStyle = makeStyles(() => ({
  root: {
    backgroundColor: "white",
    borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
  },
  greenButton: {
    border: "#1eb980",
    boxShadow: "none",
    borderRadius: "1px",
    textTransform: "uppercase",
    backgroundColor: "#1eb980",
    color: "white",
    padding: "4px 6px",
    fontSize: "10px",
    ["&:hover"]: {
      cursor: "pointer",
      backgroundColor: "#1eb980",
      boxShadow: "none",
    },
  },
  verticalLine: {
    display: "flex",
    color: "#b7b6b6",
    alignItems: "center",
    paddingRight: "10px",
    fontWeight: "lighter",
  },
}));

/**
 * Page panel header
 * @param {[object]} buttonItems
 * @param {string} buttonItems.title
 * @param {object} buttonItems.handleClick
 * @param {number} buttonItems.width
 * @param {object} searchHandler
 * @param {string} searchPlaceHolder
 * @param {boolean} isSearchEnabled
 * @param {JSX Element} children
 * @returns
 */

const PagePanelHeaderComponent = (props) => {
  const {
    otherItems,
    buttonItems,
    searchHandler,
    searchPlaceHolder,
    isSearchEnabled,
  } = props;

  const classes = headerComoponentStyle();

  return (
    <Grid container justifyContent="space-between" className={classes.root}>
      <Grid item xs={12} container>
        {isSearchEnabled ||
          (false && (
            <Fragment>
              <Grid item>
                <SearchBoxComponent
                  style={{ border: 0, fontSize: 13 }}
                  placeholderText={
                    searchPlaceHolder
                      ? searchPlaceHolder
                      : "Search contractors..."
                  }
                  setSearchQuery={searchHandler}
                />
              </Grid>
              <div className={classes.verticalLine}>|</div>
            </Fragment>
          ))}

        {buttonItems &&
          buttonItems.map(({ title, handleClick, width }, index) => {
            return (
              <Grid
                key={`${title}-${index}`}
                item
                xs={width}
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "4px 4px 4px 30px",
                }}
              >
                <Button
                  key={title}
                  className={classes.greenButton}
                  onClick={handleClick}
                >
                  {title}
                </Button>
              </Grid>
            );
          })}
        {props.children ? props.children : ""}
      </Grid>
    </Grid>
  );
};

export default PagePanelHeaderComponent;
